import type { PageHelper } from 'page-lib/global-types';

class PageHelperImpl implements PageHelper {
  private _pageConfig: Dictionary<any> = {};

  get pageConfig(): Dictionary<any> { return this._pageConfig; }
  set pageConfig(value: Dictionary<any>) { this._pageConfig = value; }

  getPageConfig<T extends Dictionary<any>>(): T { return this.pageConfig as T; }
  setPageConfig<T extends Dictionary<any>>(value: T): void { this.pageConfig = value; }

  getPC<T extends Dictionary<any>>(): T { return this.getPageConfig<T>(); }
  setPC<T extends Dictionary<any>>(value: T): void { this.setPageConfig(value); }
}

export const pageHelper: PageHelper = new PageHelperImpl();
